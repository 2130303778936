.modal-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  padding-top: 100px;
  cursor: auto;

  .modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    height: 85%;
    max-width: 1350px;
    max-height: 90%;
    min-width: 50%;
    background: #FFFFFF;
    overflow: auto;

    .modal-content {
      box-sizing: border-box;
      padding: 40px;
      // margin: 0 20px;
    }

    &-close {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 85%;
      height: 85%;
      max-width: 1350px;
      max-height: 90%;
      min-width: 50%;
      width: 90%;
      max-width: 1350px;
      & > img {
        // transform: translateY(-20px);
        position: absolute;
        top: -25px;
        right: 0;
        width: 20px;
        height: 20px;
        margin-bottom: 10px;
      }
      text-align: right;
    }

  }

}