/* about */
.about-contain {
  box-sizing: border-box;
  padding: 97px 0 67px 60px;
  background: url('../../assets/images/about-bg.png') no-repeat;
  background-position: 100.5% bottom;
  background-size: 60%;
  height: 900px;
  overflow: hidden;
  position: relative;

  .about-contain-wrapper {
    max-width: 3000px;

    .about-contain-section {
      max-width: 1600px;
      margin: 0 100px;
    }

    section > h1 > span {
      font-size: 62px;
      font-weight: 600;
      line-height: 86px;
      font-family: HKGroteskPro-Medium, serif;
      background: linear-gradient(270deg, #4ddaeb 0%, #5eeec3 100%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }

    section > section {
      width: 893px;
      font-size: 21px;
      font-family: HKGroteskPro-Regular, serif;
      font-weight: 500;
      color: #333333;
      line-height: 42px;
      margin-bottom: 70px;
    }
  }

  div.more-btn {
    cursor: pointer;
    width: 254px;
    height: 68px;
    box-sizing: border-box;
    border-radius: 34px;
    padding: 1px;
    background: linear-gradient(
      270deg,
      rgba(77, 218, 234, 1),
      rgba(94, 238, 196, 1)
    );

    & > div {
      width: 100%;
      height: 100%;
      border-radius: 34px;
      background: #ffffff;
      text-align: center;
      overflow: hidden;
      & > span {
        font-size: 20px;
        line-height: 60px;
        font-family: Feather;
        font-weight: 500;
        color: rgba(77, 218, 234, 1);
        letter-spacing: 6px;
      }
    }
  }

  .about-group {
    & > img {
      position: absolute;
      // transition: right 1s ease-in-out, opacity 2s ease-in-out;
      opacity: 0;

      &.about1 {
        width: 20%;
        right: 110%;
        bottom: 320px;
        transition: right 1s ease-in-out, opacity 1s ease-in-out;
      }
      &.about2 {
        width: 20%;
        right: -50%;
        bottom: 160px;
        transition: right 2s ease-in-out, opacity 1s ease-out;
      }
      &.about3 {
        width: 20%;
        right: -50%;
        bottom: 100px;
        transition: right 3s ease-in-out, opacity 1s ease-out;
      }
    }
    &.show > img {
      opacity: 1;
      &.about1 {
        // right: 380px;
        right: 35%;
        bottom: 320px;
      }
      &.about2 {
        // right: 620px;
        right: 45%;
        bottom: 160px;
      }
      &.about3 {
        right: 100px;
        bottom: 100px;
      }
    }
  }
}

.approach-wrapper {
  display: flex;
  justify-content: center;
}
.approach-contain {
  max-width: 1920px;
  margin: 0 168px;

  &-head {
    font-size: 36px;
    font-family: HKGroteskPro-Medium, serif;
    // font-weight: 600;
    color: #333333;
    line-height: 75px;
    margin-left: 32px;
    margin-bottom: 38px;
    &::after {
      content: '';
      display: inline-block;
      width: 48px;
      height: 20px;
      background: url('../../assets/images/hand.png') no-repeat;
      background-size: cover;
      margin-left: 20px;
    }
  }

  &-article1,
  &-article2 {
    &-head {
      display: flex;
      align-items: center;
      font-size: 23px;
      font-family: HKGroteskPro-Regular, serif;
      font-weight: 600;
      color: #333333;
      line-height: 32px;
      margin-bottom: 18px;
    }
    &-content {
      font-size: 17px;
      font-family: HKGroteskPro-Regular, serif;
      font-weight: 500;
      color: #999999;
      line-height: 24px;
      margin-left: 32px;
      margin-right: 32px;
    }
  }

  &-article1 {
    &-head::before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 26px;
      background: linear-gradient(360deg, #4ddaeb 0%, #5eeec3 100%);
      border-radius: 4px;
      margin-right: 24px;
    }
  }

  &-article2 {
    text-align: right;
    margin-top: 40px;
    margin-bottom: 79px;
    &-head {
      justify-content: right;
    }
    &-head::after {
      content: '';
      display: inline-block;
      width: 8px;
      height: 26px;
      background: linear-gradient(360deg, #4ddaeb 0%, #5eeec3 100%);
      border-radius: 4px;
      margin-left: 24px;
    }
  }
}

/* science */
.science-wrapper {
  .science-head {
    height: 315px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    margin-bottom: 36px;

    &-sub-title {
      font-size: 21px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #999999;
      line-height: 29px;
      letter-spacing: 9px;
    }

    &-title {
      font-size: 43px;
      font-family: HKGroteskPro-Regular, serif;
      font-weight: 600;
      color: #333333;
      line-height: 61px;
      letter-spacing: 14px;
    }
  }

  .science-technology-masked {
    background: url('~@/assets/images/technology/bg.png') no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    border: 1px solid red;
  }

  .solution-masked {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
    height: calc(900px + 95px);
    background: url('~@/assets/images/products/bg.png') no-repeat;
    background-position-y: 95px;
    background-size: cover;

    .solution-container {
      width: 1100px;
      transform: translateY(20px);

      &-product {
        display: flex;
        justify-content: space-between;
      }

      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-img {
          width: 110px;
          height: 110px;
          background: #ffffff;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.09);
          border-radius: 72px;
          text-align: center;
          line-height: 150px;
          margin-bottom: 28px;
        }

        &-name {
          font-size: 20px;
          font-family: HKGroteskPro-Regular, serif;
          font-weight: 600;
          color: #1e889a;
          line-height: 28px;
        }
      }
    }

    .solution-content-wrapper {
      width: 1200px;
      height: 684px;
      background: url('~@/assets/images/products/yuan.png') no-repeat;
      background-position: 65% 95px;
      position: relative;
    }
    .solution-content {
      width: 1080px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 5%;
      left: 4.5%;

      &-left,
      &-right {
        &-top,
        &-bottom {
          & > div {
            text-align: center;
            line-height: 61px;

            font-size: 18px;
            font-family: HKGroteskPro-Regular, serif;
            font-weight: 600;
            color: #aae1de;
          }

          & > p {
            font-size: 20px;
            font-family: HKGroteskPro-Regular, serif;
            font-weight: 600;
            color: #1e889a;
            line-height: 28px;
          }
        }
      }

      &-left {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        transform: translateY(20px);
        &-top {
          width: 265px;
          height: 61px;
          background: url('~@/assets/images/products/path0.png') no-repeat;
          background-size: contain;
          transform: translate(150px, -10px);
          & > p {
            transform: translateX(-30px);
          }
          margin-bottom: 25px;
        }
        &-bottom {
          width: 221px;
          height: 48px;
          background: url('~@/assets/images/products/path1.png') no-repeat;
          background-size: contain;
          transform: translate(115px, -20px);
          & > p {
            transform: translate(20px, 30px);
          }
        }
      }

      &-right {
        transform: translateY(20px);
        &-top {
          width: 300px;
          height: 112px;
          background: url('~@/assets/images/products/path3.png') no-repeat;
          background-size: contain;
          transform: translate(-100px, -20px);
          & > div {
            color: #e3c7d3;
          }
          & > p {
            transform: translate(100px, 30px);
            color: #ed6877;
          }
        }
        &-bottom {
          width: 219px;
          height: 48px;
          background: url('~@/assets/images/products/path2.png') no-repeat;
          background-size: contain;
          transform: translate(-140px, -10px);
          & > div {
            color: #e3c7d3;
            transform: translateY(-15px);
          }
          & > p {
            transform: translate(100px, 30px);
            color: #ed6877;
          }
        }
      }

      &-center {
        text-align: center;
        & > img {
          margin-bottom: 30px;
        }
        & > p {
          white-space: nowrap;
          font-size: 18px;
          font-family: HKGroteskPro-Regular, serif;
          font-weight: 600;
          color: #333333;
          line-height: 25px;
          margin-bottom: 20px;
        }
      }

      &-left-item,
      &-right-item {
        height: 76px;
        background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0.5) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.1);

        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #139b9a;

        display: flex;
        align-items: center;
        padding: 8px;
        margin-bottom: 20px;

        & > img {
          width: 16px;
          height: 16px;
        }

        & > div {
          // border: 1px solid red;
        }

        & > div > p {
          white-space: nowrap;
          line-height: 18px;
        }
      }

      &-left-item {
        border-radius: 0px 100px 100px 0px;
        justify-content: right;
        & > img {
          margin-left: 12px;
        }
        & > div > p {
          text-align: right;
        }
      }

      &-right-item {
        border-radius: 100px 0px 0px 100px;
        & > img {
          margin-right: 12px;
        }
      }

      .item1 {
        width: 235px;
      }

      .item4 {
        width: 235px;
      }

      .item5 {
        width: 291px;
        height: 54px;
      }

      .item6 {
        width: 235px;
        height: 54px;
      }
    }
  }
}

/* news */
.news-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .news-contain {
    max-width: 1200px;
    // border: 1px solid red;
    height: 751px;

    &-more {
      content: 'MORE';
      display: block;
      text-align: right;
      margin: 32px 194px;

      &::after {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 2px solid #333333;
        border-top: 0;
        border-left: 0;
        transform: rotate(-45deg) translateY(-2px);
      }

      & > a {
        font-size: 20px;
        font-family: HKGroteskPro-Regular, serif;
        font-weight: 600;
        color: #333333;
        line-height: 28px;
        margin-right: 7px;
      }
    }

    &-content {
      height: 590px;
    }
  }
}

/* careers*/
.careers-wrapper {
  width: 100%;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .careers-head {
    width: 100%;
    height: 422px;
    background: url('~@/assets/images/careers/bg.png') no-repeat;
    background-size: 102% 120%;
    background-position: -2px -20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    &-container {
      width: 1200px;

      & > img {
        width: 261px;
        height: 49px;
      }

      & > h2 {
        font-size: 53px;
        font-family: HKGroteskPro-Regular, serif;
        font-weight: 600;
        color: #ffffff;
        line-height: 74px;
        letter-spacing: 17px;
      }

      & > p {
        font-size: 37px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 52px;
      }
    }
  }

  .careers-content {
    width: 1200px;
    margin-bottom: 31px;

    & > header {
      text-align: center;
      margin: 82px 0 74px;
    }

    &-subtitle {
      font-size: 21px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #999999;
      line-height: 29px;
      letter-spacing: 9px;
    }
    &-title {
      margin-top: 16px;
      font-size: 43px;
      font-family: HKGroteskPro-Regular, serif;
      font-weight: 600;
      color: #333333;
      line-height: 61px;
      letter-spacing: 14px;
    }

    &-contain {
      display: flex;
      justify-content: space-between;

      &-item {
        width: 219px;
        height: 328px;
        background: #ffffff;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.09);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        position: relative;

        &::before {
          content: '';
          display: inline-block;
          width: 51px;
          height: 11px;
          background: linear-gradient(90deg, #5eeec3 0%, #4ddaeb 100%);
          border-radius: 0px 0px 10px 10px;

          position: absolute;
          top: 0;
          left: 24px;
        }

        & > div {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: all 500ms ease-in-out;
          display: flex;
          flex-direction: column;

          &.item-wrapper-focus {
            box-sizing: border-box;
            padding: 30px 26px;

            & > header {
              text-align: center;
              font-size: 23px;
              font-family: HKGroteskPro-Regular, serif;
              font-weight: 600;
              color: #333333;
              line-height: 32px;
              border-bottom: 2px solid rgba(0, 0, 0, 0.05);
              padding-bottom: 10px;
              margin-bottom: 17px;
            }

            & > section {
              font-size: 15px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 24px;
            }
          }
          &.selected-focus {
            opacity: 1;
          }

          &.item-wrapper-default {
            align-items: center;
            justify-content: space-between;

            & > div {
              width: 151px;
              height: 151px;
              margin-top: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          &.selected-default {
            opacity: 1;
          }
        }
      }

      .img0 {
        width: 139px;
        height: 82px;
      }
      .img1 {
        width: 150px;
        height: 130px;
      }
      .img2 {
        width: 147px;
        height: 130px;
      }
      .img3 {
        width: 139px;
        height: 83px;
      }

      &-desc {
        width: 150px;
        text-align: center;
        box-sizing: border-box;
        padding-bottom: 10px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.05);
        margin-bottom: 20px;

        font-size: 23px;
        font-family: HKGroteskPro-Regular, serif;
        font-weight: 600;
        color: #333333;
        line-height: 32px;
      }
    }
  }
}

/* founder*/
.founder-container {
  width: 100%;
  height: 704px;
  .top-container {
    width: 100%;
    height: 231px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      height: 96px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .enTxt {
        height: 32px;
        font-weight: 400;
        font-size: 21px;
        color: #999999;
        line-height: 32px;
        letter-spacing: 6px;
        text-align: justified;
        font-family: PingFang SC, PingFang SC;
      }
      .chinaTxt {
        height: 32px;
        font-weight: 400;
        font-size: 43px;
        color: #333333;
        line-height: 32px;
        letter-spacing: 4px;
        text-align: justified;
        font-family: PingFang SC, PingFang SC;
      }
    }
  }
  .bottom-container {
    height: 473px;
    background: url('~@/assets/images/founderBg.png') no-repeat;
    background-size: cover;

    .content {
      padding: 50px 110px 59px 160px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        .name {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 43px;
          color: #ffffff;
          text-align: justified;
          font-style: normal;
          text-transform: none;
          margin-bottom: 22px;
          .bigName {
            font-size: 48px;
          }
        }
        .desc {
          height: 24px;
          font-family: Poppins, Poppins;
          font-weight: 400;
          font-size: 28px;
          color: #ffffff;
          line-height: 24px;
          text-align: justified;
          font-style: normal;
          text-transform: none;
          margin-bottom: 44px;
        }
        .divide {
          width: 817px;
          height: 2px;
          position: relative;
          background: #ccffffff;
          margin-bottom: 44px;
          .divide1 {
            width: 45px;
            height: 4px;
            background: #ffffff;
            position: absolute;
            top: -1px;
          }
        }
        .personDesc {
          width: 817px;
          height: 96px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 17px;
          color: #ffffff;
          line-height: 32px;
          text-align: justified;
          font-style: normal;
          text-transform: none;
          .contentItem {
            display: flex;
            align-items: center;
            //圆点
            .dot {
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: #ffffff;
              margin-right: 10px;
            }
          }
        }
      }
      .icon {
        height: 370px;
        width: 370px;
      }
    }
  }
}
.careers-culture-wrapper {
  .careers-content {
    & > header {
      text-align: center;
      margin: 144px 0 74px;
    }

    &-subtitle {
      font-size: 21px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #999999;
      line-height: 29px;
      letter-spacing: 9px;
    }
    &-title {
      margin-top: 16px;
      font-size: 43px;
      font-family: HKGroteskPro-Regular, serif;
      font-weight: 600;
      color: #333333;
      line-height: 61px;
      letter-spacing: 14px;
    }
  }

  .careers-content-contain {
    width: 100%;
    height: 668px;
    background: linear-gradient(180deg, #56c1cc 0%, #20799e 100%);

    .careers-culture-content {
      width: 100%;
      height: 668px;
      background: url('~@/assets/images/careers/fill.png') no-repeat;
      background-size: 100% 50%;
      background-position-y: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

/* contact */
.contact-wrapper {
  height: 900px;
  background: url('~@/assets/images/contact/hand.png') no-repeat;
  background-size: cover;
  position: relative;

  .contact-content {
    position: absolute;
    top: 117px;
    right: 208px;
    &-head {
      font-size: 61px;
      font-family: HKGroteskPro-Regular, serif;
      font-weight: 600;
      color: #52e0df;
      line-height: 86px;
      // background: linear-gradient(270deg, #4DDAEB 0%, #5EEEC3 100%);
    }

    &-detail {
      font-size: 16px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;

      & > li {
        margin: 23px 0px 47px;
        > img {
          height: 20px;
          width: 20px;
          margin-right: 40px;
          vertical-align: middle;
        }
      }

      .contact-content-address-wrapper {
        width: 460px;
        height: 320px;
        overflow: hidden;
        .contact-content-address {
          width: 460px;
          height: 460px;
          background: #fafcfe;
        }
      }
    }
  }
}

footer {
  padding: 0 60px;
  box-sizing: border-box;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #999999;
  letter-spacing: 0.8px;
  .home-about-ban {
    height: 60px;
    line-height: 20px;
    transform: translateY(-20px);
    & > a {
      color: #666;
    }
  }
}

// @media screen() {

// }
