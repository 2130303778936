.detail-wrapper {
  background: #dddddd;
  overflow: auto;
  height: 100vh;
  position: relative;

  & > header {
    width: 100%;
    height: 422px;
    background: url('~@/assets/images/news/detail-bg.png') no-repeat;
    background-size: 101%;
    background-position-x: -1px;
  }

  .detail-container {
    position: absolute;
    left: 50%;
    // bottom: 10px;
    // height: 80vh;
    transform: translate(-50%, -260px);
    width: 1200px;

    & > header {
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.09);
      border-radius: 10px;
      padding: 45px 40px;
      box-sizing: border-box;
      min-height: 179px;
      margin-bottom: 30px;

      font-size: 24px;
      font-family: HKGroteskPro-Regular, serif;
      font-weight: 600;
      color: #333333;
      line-height: 33px;
      box-sizing: border-box;

      display: flex;

      &::before {
        flex-shrink: 0;
        content: '';
        display: block;
        width: 10px;
        height: 29px;
        background: linear-gradient(360deg, #4DDAEB 0%, #5EEEC3 100%);
        border-radius: 5px;

        margin-right: 25px;
      }
    }

    & > section {
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.09);
      border-radius: 10px;
      padding: 45px 40px;
      box-sizing: border-box;
      margin-bottom: 100px;

      font-size: 20px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #333333;
      line-height: 48px;

      & > article {
        margin-top: 48px;
      }

      & > article:first-child {
        margin-top: 0;
      }

    }
  }
}