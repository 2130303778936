.ns-wrapper {
  background: #dddddd;
  overflow: hidden;
  height: 100vh;
  position: relative;

  & > header {
    width: 100%;
    height: 422px;
    background: url('~@/assets/images/news/news-bg.png') no-repeat;
    background-size: cover;
  }

  &-container {
    width: 1200px;
    height: 80vh;
    overflow-y: auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.09);
    border-radius: 10px;

    position: absolute;
    left: 50%;
    transform: translate(-50%, -260px);

    padding: 44px;
    box-sizing: border-box;

    &-item {
      display: flex;
      padding-bottom: 24px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.03);
      margin-bottom: 24px;

      & > div {
        width: 200px;
        height: 100px;
        // border: 1px solid #333333;

        img {
          width: 100px;
          height: 100px;
        }

      }

      & > p {
        // margin-left: 52px;
        width: 100%;

        & > a {
          font-size: 22px;
          font-family: HKGroteskPro-Regular, serif;
          font-weight: 600;
          color: #333333;
          line-height: 30px;

          overflow: hidden;
          text-overflow: ellipsis;
          display:-webkit-box; //作为弹性伸缩盒子模型显示。
          -webkit-box-orient:vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
          -webkit-line-clamp:3; //显示的行
        }

      }

    }

    &-item:last-child {
      border-bottom: 0;
    }

  }

}