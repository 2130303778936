.slider-wrapper {

  &-mask {
    width: 1200px;
    height: 301px;
    background: rgba(255, 255, 255, 0.13);
    border-radius: 10px;
    padding: 75px 111px;
    box-sizing: border-box;
    position: relative;
    // border: 1px solid red;

    &::before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 26px;
      background: #FFFFFF;
      border-radius: 4px;

      position: absolute;
      left: 79px;
      top: 77px;
    }

    &:hover > .slider-wrapper-content {
      .slider-wrapper-content-before,
      .slider-wrapper-content-after {
        opacity: 1;
      }
    }
  }

  &-content {
    position: relative;
    height: 200px;

    &-before,
    &-after {
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 3px solid #FFFFFF;
      position: absolute;
      top: 70px;
      opacity: 0;
      cursor: pointer;
    }

    &-before {
      border-top: 0;
      border-right: 0;
      transform: rotate(45deg);
      left: -80px;
    }

    &-after {
      float: right;
      border-top: 0;
      border-left: 0;
      transform: rotate(-45deg);
      right: -80px;
    }


    &-item {
      flex-shrink: 0;
      position: absolute;
      width: 100%;
      margin-right: 200px;
      display: block;
      opacity: 0;
      transition: all 1s ease-in;

      & > header {
        font-size: 23px;
        font-family: HKGroteskPro-Regular, serif;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 32px;
        margin-bottom: 18px;
      }

      & > section {
        font-size: 17px;
        font-family: HKGroteskPro-Regular, serif;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 24px;
      }

      &.select {
        opacity: 1;
      }
    }
  }

  &-footer {
    // margin-top: 46px;
    display: flex;
    justify-content: center;
    height: 50px;

    & > li {
      margin-right: 25px;
      cursor: pointer;
    }
    & > li:last-child {
      margin-right: 0;
    }

    .slider-btn {
      width: 134px;
      height: 4px;
      margin: 35px 0;
      background: #56C1CC;
      border-radius: 6px;
      opacity: 0.42;
      transition: opacity 1s ease-in;

      &.select {
        opacity: 1;
      }
    }

  }

}