.lo-wrapper {
  display: flex;
  height: 100%;

  & > li {
    margin-right: 2px;
    width: 399px;
    &:last-child {
      margin-right: 0;
    }
  }

  .lo-item {
    flex: 1;
    position: relative;
    height: 100%;
    width: 100%;

    &-default {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 1;
      transition: opacity 1s ease-in-out;
      display: flex;
      align-items: flex-end;
      &-desc {
        background: #32AEBD;
        height: 168px;
        font-size: 22px;
        font-family: HKGroteskPro-Medium, serif;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 26px;
        box-sizing: border-box;
        padding: 25px 25px;
        width: 399px;
      }
    }

    &-selected {
      height: 100%;
      position: relative;
      opacity: 0;
      transition: opacity 1s ease-in-out;

      &-content {
        position: relative;
        z-index: 9;
        height: 100%;
        padding: 43px 33px;
        box-sizing: border-box;
        background-color: rgba(0,0,0,0.5);
        &-head {
          font-size: 28px;
          font-family: HKGroteskPro-Medium, serif;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 50px;
        }

        &-desc {
          font-size: 15px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 20px;
          margin-top: 20px;
        }

        &-btn {
          cursor: pointer;
          position: absolute;
          bottom: 50px;
          left: 50%;
          transform: translateX(-50%);
          width: 175px;
          height: 55px;
          background: #FFFFFF;
          border-radius: 49px;

          display: inline-flex;
          align-items: center;
          // padding-left: 27px;
          box-sizing: border-box;
          overflow: hidden;

          & > a {
            font-size: 18px;
            font-family: HKGroteskPro-Regular, serif;
            font-weight: 600;
            color: #56C1CC;
            line-height: 25px;

            padding: 24px 90px 24px 27px;
            white-space: nowrap;
          }

          & > a > img {
            margin-right: 8px;
          }
        }
      }
    }
    &-selected::after {
      content: '';
      display: block;
      position: absolute;
      background: rgba(16, 160, 179, 0.15);
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &-selected {
      opacity: 0;
      transition: opacity 800ms ease-in-out;
    }

    .lo-default {
      width: 390px;
      background:rgba(16, 160, 179, 0.15);
      .lo-item-default-desc {
        width: 100%;
      }
    }
  }

  .lo-selected {
    .lo-item-default {
      opacity: 0;
    }

    .lo-item-selected {
      opacity: 1;
    }

  }
}