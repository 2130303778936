.header {
  display: flex;
  justify-content: space-between;
  padding: 17px 60px;
  box-sizing: border-box;
  position: sticky;
  top:0;
  bottom: auto;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  background: #FFFFFF;
  max-width: 3000px;

  .logo {
    height: 64px;
    overflow: hidden;
    & > img {
      width: 190px;
      transform: translateY(10px);
    }
  }

  .header-nav {
    margin-top: 25px;
    position: absolute;
    right: 23px;
  }

}