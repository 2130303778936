.so-wrapper {
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin: 40px 75px;

  .so-container {
    flex: 1;
    .so-nav {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 610px;
      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 600px;
        height: 150px;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
        margin-bottom: 2px;
        padding: 0 43px;
        box-sizing: border-box;
        transition: all 1s ease-in-out;
        cursor: pointer;

        &-icon {
          width: 104px;
          height: 88px;
          box-sizing: border-box;

          background: rgba(255, 255, 255, 0.25);
          border-radius: 50%;
          text-align: center;
          line-height: 84px;
          margin-right: 75px;

          & > img {
            width: 56px;
            vertical-align: middle;
          }
        }

        &-title {
          width: 410px;
          font-size: 22px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.3);
          line-height: 30px;
        }

      }

    }

    .so-nav.selected {
      .so-nav-item {
        width: 610px;
        height: 196px;
        background: #1A7A8A;
        &-title {
          color: #FFFFFF;
        }
      }
    }
  }

  .so-content-wrapper {
    flex: 1;
    background: url("~@/assets/images/science.png") no-repeat;
    background-size: 100%;
    background-position: bottom;
    height: 652px;

    .so-content {
      // width: 620px;
      height: 652px;
      overflow: hidden;
      // overflow-y: scroll;
      background: linear-gradient(135deg, rgba(26, 122, 138, 1) 50%, rgba(5, 131, 141, 0.37) 100%);
      padding: 45px 40px;
      box-sizing: border-box;
      font-size: 18px;
      // font-family: PingFang-SC-Medium, PingFang-SC;
      font-family: HKGroteskPro-Regular, serif;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 32px;
      transition: all 1s ease-in-out;
      // text-align: justify;
    }

  }

}