@font-face {
  font-family: "HKGroteskPro-Medium";
  src: url('~@/assets/fonts/HKGroteskPro-Medium.woff2');
}

@font-face {
  font-family: "HKGroteskPro-Regular";
  src: url('~@/assets/fonts/HKGroteskPro-Regular.woff2');
}

@font-face {
  font-family: "Feather";
  src: url('~@/assets/fonts/Feather.ttf');
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: HKGroteskPro-Regular, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* 隐藏滚动条 */
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}

body::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

div, span, section, nav, header, footer, a, ul, li, img, p,
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

ul, li {
  list-style: none;
  text-decoration: none;
  line-height: normal;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
