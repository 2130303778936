.ab-wrapper {
  overflow: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  .ab-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }

  .abs {
    width: 100%;
    display: flex;
    & > div.img-wrapper > .img {
      width: 561px;
      height: 312px;
    }

    & > section.desc {
      font-size: 15px;
      font-family: HKGroteskPro-Regular, serif;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      width: auto;
    }
    &.abs1 {
      & > section.desc {
        margin-right: 20px;
      }
      margin-bottom: 20px;
    }

    &.abs2 {
      & > section.desc {
        margin-left: 20px;
      }
    }
  }

  .abs:last-child {
    margin-bottom: 0;
  }

}