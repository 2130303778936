.nav-wrapper {

  .nav-container {
    display: flex;
    align-items: baseline;

    &-item {
      flex: 1;
      text-align: center;
      width: 120px;

      .nav-text {
        font-size: 14px;
        font-family: HKGroteskPro-Regular, serif;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 20px;
        white-space: nowrap;
        cursor: pointer;
      }

      .nav-text:hover {
        font-weight: 600;
        color: #56C1CC;
      }

      &-subnav {
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.09);
        border-radius: 10px;
        box-sizing: border-box;
        margin-top: 2px;

        padding: 0;
        text-align: center;
        height: 0;
        overflow: hidden;
        transition: all 100ms ease-in-out;

        &-item {
          margin-bottom: 12px;
        }
        &-item:last-child {
          margin-bottom: 0;
        }

      }

    }

    &-item:last-child {
      margin-right: 0;
      transform: translateX(-30px);
    }

    &-item.item-subnav {
      .nav-container-item-content {
        margin-bottom: 8px;

        &::after {
          content: '';
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 1px;
          border: 2px solid rgba(0, 0, 0, 0.85);
          border-top: 0;
          border-left: 0;
          transform: translate(10px, -2px) rotate(45deg);
        }
      }
    }

    &-item.item-subnav:hover {
      .nav-container-item-content.nav-text {
        &::after {
          transform: translate(10px, 3px) rotate(-135deg);
        }
        font-weight: 600;
        color: #56C1CC;
      }
      .nav-container-item-subnav {
        padding: 14px 0;
        height: auto;
      }
    }

  }

  .nav-container-item-content-translate {
    transform: translateY(7px);
  }

}